.flex {
  display: flex;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-space-between {
  justify-content: space-between;
}

.white-space-nowrap {
  white-space: nowrap;
}

.align-items-center {
  align-items: center;
}

.width-fit-content {
  width: fit-content;
}

.width-64 {
  width: 64px;
}

.width-275 {
  width: 275px;
}

.width-300 {
  width: 300px;
}

.width-20-per {
  width: 20%;
}

.width-50-per {
  width: 50%;
}

.width-80-per {
  width: 80%;
}

.width-100-per {
  width: 100%;
}

.height-fit-content {
  height: fit-content;
}

.height-75 {
  height: 75px;
}

.height-100-per {
  height: 100%;
}

.mr-0 {
  margin: 0px;
}

.mr-tp-20 {
  margin-top: 20px;
}

.mr-rt-6 {
  margin-right: 6px;
}

.pd-10 {
  padding: 10px;
}

.pd-lt-20 {
  padding-left: 20px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.yellow-text {
  color: #ffc801;
}

.blue-text {
  color: blue;
}

.white-text {
  color: #ffffff;
}

.grey-text {
  color: #999999;
}

.purple-text {
  color: #271340;
}

.light-purple-text {
  color: #8d45d0;
}

.bg-gray {
  background-color: #f5f5f5;
}

.error-input-color {
  border: 2px solid red !important;
}

.font-family-project {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.font-family-Manrope {
  font-family: Manrope;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-36 {
  font-size: 36px;
}

.font-weight-600 {
  font-weight: 600;
}

.text-decoration-underline {
  text-decoration: underline;
}

@keyframes aitf {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.purpleBtn {
  gap: 10px;
  color: #ffffff;
  display: flex;
  padding: 14px 34px;
  background: #271340;
  transition: all 0.3s ease;
  align-items: center;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
}

.purpleBtn:hover {
  background: #8d45d0;
}

.main-layout {
  width: 100vw;
  height: 100vh;
}

.afterAuthorize {
  display: flex;
  height: calc(100vh - 60px);
}
