.App {
  width: 100vw;
  height: 100vh;
}

body {
  background-color: #edf2f9;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}
