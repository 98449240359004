@media (max-width: 390px) {
    .table-cell {
      font-size: 8px; 
      padding: 2px; 

    }
  
    .table-header {
      font-size: 14px; 
    }
  
    .button-container {
      flex-direction: column; 
      gap: 5px;
    }
}