
  .card-container {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    margin-left: -10px;
    margin-top: -10px;
    flex-wrap: wrap; 
  }
  
  .color-card {
    width: 210px;
    padding: 10px;
    border-radius: 10px;
    background-color: #BBDEFB; 
    color: #4285F4;
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .color-card:hover {
    transform: translateY(-20px); 
    background-color: #4285F4;
    color: white;
  }
  
  .graph-container{
    min-height:350px;
    margin-left: 130px;
  }
  
  /* Media query for iPhone 13 and smaller screens */
@media (max-width: 390px) {
  .card-container {
    justify-content: center; 
    margin-left: 0; 
  }

  .color-card {
    width: 60%; 
    padding: 8px; 
    font-size: 8px; 
  }

  .graph-container {
    margin-left: 0; 
    min-height: 250px; 
  }
}